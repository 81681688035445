<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <!-- <el-form-item label="产品:">
              <el-select
                v-model="product_id"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
                @change="stateSelectOnclick"
              >
                <el-option key="state-all" label="所有" value=""></el-option>
                <el-option
                  v-for="item in product_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="所在大州:">
              <el-select
                v-model="state_id"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
                @change="stateSelectOnclick"
              >
                <el-option key="state-all" label="所有" value></el-option>
                <el-option
                  v-for="item in state_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在地区:">
              <el-select v-model="region_id" clearable placeholder="请选择" loading-text="加载中...">
                <el-option key="region-all" label="所有" value></el-option>
                <el-option
                  v-for="item in region_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getGlobalParentList"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getGlobalParentList"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="newTypeOnClick"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="global_parent_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="产品" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                product_list_map.get(scope.row.product_id)
                ? product_list_map.get(scope.row.product_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="伙伴名称" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="网址">
            <template slot-scope="scope">
              <a
                style="font-weight: bold"
                :href="scope.row.web_url"
                target="_blank"
                v-if="scope.row.web_url "
              >
                {{
                scope.row.web_url
                }}
              </a>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="所在大州" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                state_map.get(scope.row.state_id)
                ? state_map.get(scope.row.state_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="所在地区" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                region_map.get(scope.row.region_id)
                ? region_map.get(scope.row.region_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="editModelOnclick(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "GlobalPartnersListView",
  data() {
    return {
      loading: false,
      state_id: "",
      state_list: [],
      state_map: {},
      region_id: "",
      region_list: [],
      region_list_map: {},
      region_map: {},
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      global_parent_list: [],
      product_id: "",
      product_list: [],
      product_list_map: {}
    };
  },
  created() {
    this.state_map = new Map();
    this.region_map = new Map();
    this.region_list_map = new Map();
    this.product_list_map = new Map();
    this.getStateList();
    this.getProductList();
    this.getGlobalParentList();
  },
  methods: {
    getProductList() {
      let _me = this;
      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/product/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.product_list =
              res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.product_list) {
              _me.product_list_map.set(i.id, i);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getStateList() {
      let _me = this;
      let params = {
        status: 1
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/find/tree", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.state_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.state_list) {
              _me.state_map.set(i.id, i);
              if (i.region_list && i.region_list.length > 0) {
                _me.region_list_map.set(i.id, i.region_list);
                for (let j of i.region_list) {
                  _me.region_map.set(j.id, j);
                }
              }
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err + "---" || "获取数据失败",
            type: "error"
          });
        });
    },
    getGlobalParentList() {
      let _me = this;
      let params = {
        state_id: _me.state_id,
        region_id: _me.region_id,
        // product_id: _me.product_id,
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.global_parent_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newTypeOnClick() {
      this.$router.push("global-partners-detail");
    },
    editModelOnclick(model) {
      this.$router.push("global-partners-detail?id=" + model.id);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      _me.$common
        .httpPost("/api/brand/global_partners/admin/delete", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getGlobalParentList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    stateSelectOnclick(value) {
      let _me = this;
      _me.region_id = "";
      _me.region_list = _me.region_list_map.get(value)
        ? _me.region_list_map.get(value)
        : [];
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getGlobalParentList();
    }
  }
};
</script>

<style scoped></style>
